.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.body{
  padding: 0;
  margin: 0;
  width: auto; /* or a fixed value if needed */
  overflow-x: hidden;
}



.imgBox{
  /* border: 2px solid fuchsia; */
  height: auto;
  width: auto;
  padding: 0;
  /* margin: px; */


}
.imgBox img{
  height: 100%;
  width: 100%;
  overflow: hidden;
  border-radius: 5px;
            transition: 0.2s all ease-in-out;
    margin-top: 10px;

}

.imgBox img:hover {
  transform: scale(1.1); /* (150% zoom - Note: if the zoom is too large, it will go outside of the viewport) */
        /* box-shadow: 0 4px 8px 0 white, 0 4px 8px 0 pink; */

}

.biglogoBox{
  /* border: 2px solid blue; */
  width: 100%;
  /* height: 8%; */
  display: flex;
  justify-content: center; 
  align-items: center;
}
.Text-1{
  font-size: 17px;
  font-style: normal;
font-family: 'Assistant', sans-serif;
  font-weight: 300;
color:"gainsboro"    /* font-family: 'Open Sans'; */
/* font-family: 'Inter', sans-serif; */
    /* line-height: 2rem; */
      /* letter-spacing: 0.1rem; */


}
.Heading-1{
  font-size: 42px;
  font-weight: bold;
  font-family: 'Inter', sans-serif;
  letter-spacing: 0.1rem;
  /* color: black; */
  
}

.card{
  background-color: rgb(255, 164, 179);
  color: black;
}


.imgbox{
  object-fit: cover;
  width: 100%;
  height: 100%;
}
.collab{
  background-attachment: fixed;
  /* background-image: url("./assets/collabBg.webp"); */
  /* background-repeat: no-repeat; */
  background-color: #fc0c8c;
  background-size: cover;
}
.animate
  {
     text-transform: uppercase;
    background-image: linear-gradient(
      -225deg,
      #766b9e 0%,
      #c9b5df 29%,
      #ff1361 67%,
      #fff800 100%
    );
    background-size: auto auto;
    background-clip: border-box;
    background-size: 200% auto;
    color: #fff;
    background-clip: text;
    /* text-fill-color: transparent; */
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    animation: textclip 2s linear infinite;
    display: inline-block;
        /* font-size: 190px; */
  }
  @keyframes textclip {
    to {
      background-position: 200% center;
    }
  }

  .Card:hover{
    background-color: rgb(188, 54, 76);
    /* background-color: rgb(241, 134, 152); */
    border-radius: 10px;
  }


  /* --------------- multi carusel-------- */
  
.overlay {
  opacity: 1;
  display: block;
  width: 100%;
  height: auto;
  transition: .5s ease;
  backface-visibility: hidden;
}

.middle {
  transition: .5s ease;
  opacity: 0;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  text-align: center;
}



.container:hover .middle {
  opacity: 1;
}

.text {
  background-color: black;
  color: white;
  font-size: 16px;
  padding: 16px 16px;
  border-radius: 10px;
  width: "100%";
}

.product--image {
  width: 100%;
  height: 16em;
  object-fit: cover;
  border-radius: 50px;
}

.card {
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
  max-width: 320px;
  margin: auto 1rem;
  text-align: center;
  font-family: arial;
}

.price {
  color: grey;
  font-size: 22px;
}

.card button {
  border: none;
  outline: 0;
  padding: 12px;
  color: white;
  background-color: #000;
  text-align: center;
  cursor: pointer;
  width: 100%;
  font-size: 18px;
}

.card button:hover {
  opacity: 0.7;
}
