.sidenav {
    position: absolute;
    top: 16rem;
    left: 3.5rem;
}

.sidenav .sidenav-list {
    list-style-type: none;
    display: flex;
    gap: 2rem;
    flex-direction: column;
    position: relative;
}

.sidenav .sidenav-list a {
    text-decoration: none;
}

.sidenav .sidenav-list::before {
    content: "";
    position: absolute;
    left: 10px;
    top: 0;
    bottom: 0;
    width: 2px;
    background-color: #707070;

}

.sidenav .sidenav-list li {
    position: relative;
    color: white;
    font-size: 1rem;
    max-width: 12rem;
}

.sidenav .sidenav-list li::before {
    content: "\2022";
    color: #fc0c8c;
    font-size: 1.4rem;
    position: absolute;
    top: -3px;
    left: -1.6rem;
}

.sidenav .sidenav-list li::before:hover {
    cursor: default;
}

.sidenav .sidenav-list li:hover {
    cursor: pointer;
    transform: scale(1.2);
}