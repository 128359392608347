.membership-container {
    display: flex;
    justify-content: center;

}

.membership-form-description {
    display: flex;
    flex-direction: column;
    padding: 1.1rem 0 0 1.5rem;
    color: white;
    width: 60%;
}

.membership-form-container {
    width: 40%;
}


@media (max-width: 576px) {
    .membership-container {
        display: flex;
        flex-direction: column;
    }

    .membership-form-description {
        width: 100%;
    }

    .membership-form-container {
        width: 100%;
    }
}