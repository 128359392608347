@media(max-width:450px){

    .formflex{
        flex-direction: column;
    }

    .Details{
        width:100% !important;
        padding:12px;
    }

    .registars{
        width:100% !important;
        padding:12px;
    }

}

@media(max-width:850px){

    .formflex{
        flex-direction: column;
    }

    .Details{
        width:100% !important;
        
    }

    .registars{
        width:100% !important;
        
    }

}

#general-info-container{
    color: "rgb(252,34,157)"
}