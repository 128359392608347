.privacy-policy-container {
    margin-bottom: 5rem;
    padding: 0 5rem;
}

.privacy-policy-container .policies-container h2 {
    font-size: 1.4rem;
    color: wheat;
    margin: 0 0 0.5rem 0;
}

.privacy-policy-container .policies-container p {
    color: white;
    margin: 0 0 2rem 0
}

@media (max-width: 768px) {
    .privacy-policy-container {
        margin-bottom: 3rem;
        padding: 0 3rem;
        text-align: left;
    }

    .privacy-policy-container h1 {
        font-size: 2rem;
    }
}