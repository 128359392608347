@media (min-width: 800px) {
  #attendies-container .mainbox {
    display: flex;
    flex-wrap: wrap;
    gap: 1rem;
    flex-direction: row;
    justify-content: space-around;
  }
  #attendies-container .mainbox .leftbox {
    width: 48%;
    min-width: 500px !important;
    overflow: hidden;
  }
  #attendies-container .mainbox .leftbox img {
    width: 100% !important;
    height: auto;
  }
  #attendies-container .mainbox .rightbox {
    width: 48%;
    width: 500px !important;
  }
  #attendies-container .mainbox .rightbox .map {
    width: 100%;
  }
  #attendies-container .mainbox .rightbox .map iframe {
    width: 100%;
  }
}

.forms {
  max-width: 40rem;
  width: 100%;
  margin: 0 auto;
}
