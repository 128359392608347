@media screen and (max-width: 750px) {
    .card {
        align-items: center;
    }
    .card-img-top {
        width: 250px;
    }
}

@media screen and (max-width: 430px) {
    .card-body {
        margin-right: 0px;
    }

    .card-img-top {
        width: 250px;
        height: 235px !important;
    }
}
