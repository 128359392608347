@media screen and (max-width:500px){
    .map{
        height:450px !important;
    }
}

@media screen and (max-width:376px){
    .map{
        height:420px !important;
    }
}