.empty-cart {
    width: 50vw;
    margin: 0 auto;
    text-align: center;
    font-family: 'Maison Neue';
    font-weight: 300;

  }
  
  
  svg {
    max-width: 60%;
    padding: 5rem 3rem;
  }
  
  svg #oval, svg #plus, svg #diamond, svg #bubble-rounded {
    animation: plopp 4s ease-out infinite;
  }
  
  svg #oval:nth-child(1), svg #plus:nth-child(2), svg #diamond:nth-child(3), svg #bubble-rounded:nth-child(4),
  svg #oval:nth-child(5), svg #plus:nth-child(6), svg #diamond:nth-child(7), svg #bubble-rounded:nth-child(8),
  svg #oval:nth-child(9), svg #plus:nth-child(10), svg #diamond:nth-child(11), svg #bubble-rounded:nth-child(12),
  svg #oval:nth-child(13), svg #plus:nth-child(14), svg #diamond:nth-child(15), svg #bubble-rounded:nth-child(16) {
    animation-delay: -240ms;
  }
  
  svg #bg-line:nth-child(2) {
    fill-opacity: 0.3;
  }
  
  svg #bg-line:nth-child(3) {
    fill-opacity: 0.4;
  }
  
  @keyframes plopp {
    0% {
      transform: translate(0, 0);
      opacity: 1;
    }
    100% {
      transform: translate(0, -10px);
      opacity: 0;
    }
  }
  
  h3 {
    font-size: 2rem;
    line-height: 2rem;
    margin: 0;
    padding: 0;
    font-weight: 600;
  }
  
  p {
    color: rgba(0, 0, 0, 0.5);
    font-size: 18px;
    line-height: 24px;
    max-width: 80%;
    margin: 1.25rem auto 0 auto;
  }
  
  body {
    background: #f2f2f2;
  }
  