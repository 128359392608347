.btn:hover{
    color: white;
    background-color: rgb(68, 90, 108);
    border: none;
  }
  
  
  .Heading-1{
    font-size: calc(20px + 0.5vw);
    font-weight: bold;
    font-family: 'Inter', sans-serif;
    letter-spacing: 0.1rem;
    
  }
  
  .animate
  {
     text-transform: uppercase;
    background-image: linear-gradient(
      -225deg,
      #766b9e 0%,
      #c9b5df 29%,
      #ff1361 67%,
      #fff800 100%
    );
    background-size: auto auto;
    background-clip: border-box;
    background-size: 200% auto;
    color: #fff;
    background-clip: text;
    /* text-fill-color: transparent; */
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    animation: textclip 2s linear infinite;
    display: inline-block;
        /* font-size: 190px; */
  }
  @keyframes textclip {
    to {
      background-position: 200% center;
    }
  }
