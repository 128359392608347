.contact-us-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 8rem;
  margin-bottom: 5rem;
}

.contact-us-container .contact-form {
  width: 50%;
  background-color: #f5f5f5;
  border-radius: 5px;
  padding: 0.8rem 0;
}

.contact-us-container .contact-form form {
  width: 100%;
}

.contact-us-container .contact-form label {
  color: black;
  display: block;
  font-weight: bold;
}

.contact-us-container .contact-form input {
  border: 1px solid #ccc;
  border-radius: 4px;
  font-size: 16px;
  color: black;
}



.contact-us-container .contact-form button {
  background-color: #fc0c8c;
  color: white;
  border: none;
  padding: 14px 20px;
  margin: 8px 0;
  border-radius: 4px;
  cursor: pointer;
  font-size: 16px;
}

.contact-us-container .contact-form button:hover {
  background-color: #ba0a68
}

.contact-us-container .map-container {
  width: 45%;
  height: 30rem;
  border-radius: 5px;
}

.contact-us-container .map-container iframe {
  /* border: red 1px solid; */
  height: 100%;
  width: 100%;


}

.i4ewOd-pzNkMb-tJHJj {
  display: none !important;
}

/* @media (max-width: 768px) {
  .contact-us-container {
    flex-direction: column;
    padding: 0 2rem;
  }

  .contact-us-container .contact-form,
  .contact-us-container .map-container {
    width: 100%;
  }
} */

/* .contact-us-container {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  align-items: center;
  padding: 0 8rem;
  margin-bottom: 5rem;

}

.contact-us-container .contact-form {
  width: 50%;
}

.contact-us-container .contact-form form {
  margin: 0;
  padding: 0;
}

.contact-us-container .contact-form label {
  color: white;
}

.contact-us-container .contact-form button {
  background-color: blue;
  border: none;
  padding: 0;
}

.contact-us-container .map-container {
  width: 50%;
  height: 20rem;
  border-radius: 5px;
} */