.test-work-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin: 1.6rem 0 10rem 25.8rem;
}

.test-work-container .test-work {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 10px;

}

.test-work-container .test-work a {
    background-color: white;
    color: black;
    text-decoration: none;
    width: 15rem;
    height: 15rem;
    border-radius: 5px;


}

.test-work-container .test-work a:hover {
    background-color: #ff2b9c;
    color: white;
    transform: scale(0.8);
    transition: color 0ms, transform 300ms ease-in-out;
}

.test-work-container .test-work a div {
    height: 100%;
    display: flex;
    justify-content: center;
    text-align: center;
    align-items: center;
}

.test-work-container .test-work a div h2 {
    font-size: 1.5rem;
}

@media (max-width: 768px) {
    .sidenav {
        display: none;
    }

    .test-work-container {
        margin: 0 0 5rem 0;
    }

    .test-work-container h1 {
        font-size: 1.6rem;
    }

    .test-work-container .test-work {
        display: flex;
        flex-direction: column;
    }
}