.service-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-left: 11rem;
    margin-bottom: 5rem;
    /* border: red 1px solid; */
}

.service-cards-container {
    display: flex;
    justify-content: center;
    gap: 10px;
    width: 75%;
    margin: 2rem 0 0 0rem;
    /* border: red 1px solid; */
}

.service-cards-container .service-card {
    position: relative;
    cursor: pointer;
}

.service-cards-container .service-card img {
    width: 100%;
}

.service-cards-container .service-card h2 {
    position: absolute;
    top: 24%;
    color: black;
    font-size: 1.3rem;
    width: 100%;
    text-align: center;
}

@media (max-width: 768px) {
    .service-container {
        margin: 0 0 5rem 0;
    }

    .service-cards-container {
        display: flex;
        flex-direction: column;
        gap: 1rem;
        width: 80%;

    }

    .service-cards-container .service-card h2 {
        top: 20%;
    }

}

/* Fashion Page */
.fashion-container {
    overflow-x: hidden;
}